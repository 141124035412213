import { css } from "styled-components";
import { Color, ColorDark, Font, breakpoints } from "@/shared/Constants";



export const cardMixin = css`
  position: relative;
  column-gap: 1rem;
  width: 100%;
  height: -webkit-fill-available;
  
  &:not(:last-child)::before {
    content: "";
    position: absolute;
    right: -8px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #E0E0E0;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      background-color:${ColorDark.greySeparator};
      }

  }
    
        ${breakpoints.phone} {
          display:none;
        
    }
  }
`;

export const innerCardRightMixin = css`
  position: relative;
  padding-bottom: 1rem;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      background-color:${ColorDark.greySeparator};
      }

  }
  }
  &:not(:last-child)::before {
    content: "";
    position: absolute;
    left: -11px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #E0E0E0;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      background-color:${ColorDark.greySeparator};
      }
  }
    ${breakpoints.phone} {
          display:none;
        
    }
  }
`;

export const innerCardLefttMixin = css`
  position: relative;
  /* padding-bottom: 1rem; */
  &::after {
    content: "";
    position: absolute;
    bottom: -9px;
    left: 0px;
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      background-color:${ColorDark.greySeparator};
      }

  }
  }
  &::before {
    content: "";
    position: absolute;
    right: -9px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #E0E0E0;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      background-color:${ColorDark.greySeparator};
      }

  }
    ${breakpoints.phone} {
          display:none;
        
    }
  }
`;

export const innerMiddleMixin = css`
  position: relative;
  &:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: -9px;
    left: 0px;
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      background-color:${ColorDark.greySeparator};
      }

  }
    ${breakpoints.phone} {
          display:none;
        
    }
  }

`
export const BothCardSidesTB = css`
position: relative;
&::after {
content: "";
position: absolute;
bottom: 0;
right: 0;
width: 100%;
height: 1px;
background-color: #E0E0E0;
}
&:before {
content: "";
position: absolute;
top: -9px;
left: -12px;
width: 100%;
height: 1px;
background-color: #E0E0E0;
}
`
;

  export const BothCardSidesLR = css`
    position: relative;
    &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: -9px;
    width: 1px;
    height: 100%;
    background-color: #E0E0E0;
}
  &:before {
    content: "";
    position: absolute;
    bottom: -9px;
    left: -12px;
    width: 1px;
    height: 100%;
    background-color: #E0E0E0;
}
  `
;
