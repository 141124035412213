import { Section, Container, ExtraLink } from "./styles";
import { LinkAbsolute } from "@/shared/LinkAbsolute";
import ContentPayWallExclusive from "@/shared/ContentPayWallExclusive";
const Separator = ({ link, title, sectionClass = "", tag="h2", extras = [], target="_self", linkImage, background, color }) => {
  const DynamicTag = tag;
  return (
    <Container className={sectionClass} style={background ? {backgroundColor: background}: {}}>
      {!background ? <hr/> : null}
      <Section className={sectionClass}>
        <div className="left" style={{paddingLeft: background ? "25px": "0"}}>
        {sectionClass === "suscriptor-content back" && <ContentPayWallExclusive style={{paddingRight: background ? "5px": "0"}}/>}
          <div style={{position:"relative"}}>
            {link && <LinkAbsolute href={link} target={target} aria-label={title} />}
            <DynamicTag className="sep" style={ color ? {color: color} : {}}>{title}</DynamicTag>
          </div>

          {extras.length > 0 && <ExtraLink className="dd">
          {!sectionClass && extras?.map((element, index) => (
            <li key={`extras${index}`} style={{border: "1px solid "+ color}}>                
              <a href={element.link} style={ color ? {color: color} : {}}>{element.title}</a>
            </li>
          ))}
          </ExtraLink>}
        </div>

          <div className="sponsorHead">
            {linkImage && <img  width="300" height="50" alt="sponsor" src={linkImage} />}
          </div>
         
      </Section>
    </Container>
  );
};

export default Separator;
