import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";

export const GridStyleColor = styled.div`
  ${breakpoints.phone} {
    .halfParent,
    .box1 {
      position: relative;
      padding-bottom: 0 !important;
      > div {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0px;
          width: 100%;
          height: 1px;
          background-color: #e0e0e0;
          ${breakpoints.darkMode} {
            [data-theme="dark"] & {
              background-color: ${ColorDark.darkGrey};
            }
          }
        }
      }
    }
  }
  .GranParent.ContainerPiso6 {
    border-top: 1px solid #e0e0e0;
    &.noBorderTop {
      border-top: none;
    }
    ${breakpoints.phone} {
      border-top: none;
    }
  }

  /* Mobile */
  .module-grid {
    ${breakpoints.phone} {
      gap: 12px;
      /* &:first-child{
      transform: translateY(-24px)
    } */
      > div {
        position: relative;
        padding-bottom: 0 !important;
        height: auto;
        grid-area: auto !important;
        &:not(:last-child, .box1)::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0px;
          width: 100%;
          height: 1px;
          background-color: #e0e0e0;
        }
        /* Saco las lineas en mobile */
        &:after,
        &:before {
          display: none !important;
        }
        &.halfParent,
        &.box1,
        &.box2,
        &.box3 {
          > div {
            border-bottom: 1px solid #e0e0e0;
            ${breakpoints.darkMode} {
              [data-theme="dark"] & {
                border-bottom: 1px solid ${ColorDark.greySeparator};
              }
            }

            &:after,
            &:before {
              display: none !important;
            }
          }
        }
        border-bottom: 1px solid #e0e0e0;
        ${breakpoints.darkMode} {
          [data-theme="dark"] & {
            border-bottom: 1px solid ${ColorDark.greySeparator};
          }
        }
        &:last-child {
          border-bottom: 0 none;
        }
      }
    }
  }

  /* tablet le sacamos las lineas en 3 columnas */
  .module-grid {
    ${breakpoints.tablet} {
      grid-gap: 15px;
      > div {
        &:not(:last-child, .box1)::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0px;
          width: 100%;
          height: 1px;
          background-color: #fff;
        }
        /* Saco las lineas en mobile */
        &:after,
        &:before {
          display: none !important;
        }
        &.halfParent,
        &.box1,
        &.box2,
        &.box3 {
          > div {
            border-bottom: 1px solid #fff;
            ${breakpoints.darkMode} {
              [data-theme="dark"] & {
                border-bottom: 1px solid ${ColorDark.greySeparator};
              }
            }

            &:after,
            &:before {
              display: none !important;
            }
          }
        }
        border-bottom: 1px solid #fff;
        &:last-child {
          border-bottom: 0 none;
        }
      }
    }
  }

  .module-grid {
    &.container1PisoColumnas {
      article {
        div {
          &.half,
          &.onexone {
            .mt {
              text-align: center;
              display: flex;
              align-items: center;
              h2.title {
                ${Font.clarinVarSub2Regular};
                width: 95%;
                margin-bottom: 0;
              }
              .volanta {
                display: none;
              }
              .data-txt {
                flex-direction: column-reverse;
                margin-top: 3px;
                .ifPaywallPipe::before {
                  display: none;
                }
                p.name {
                  ${Font.clarinVarSub2Bold};
                  color: #000;
                  text-transform: capitalize;
                  font-size: 16px;
                  line-height: 17px;
                  ${breakpoints.darkMode} {
                    [data-theme="dark"] & {
                      color: ${ColorDark.textLight};
                    }
                  }
                }
              }
            }
          }
          &.half {
            .mt {
              padding-right: 0;
              .data-txt {
                align-items: center;
              }
            }
          }
          &.onexone {
            .mt {
              h2.title {
                font-size: 22px;
                line-height: 25px;
                ${breakpoints.phone} {
                  font-size: 23px;
                  line-height: 26px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
